.course-body {
  @apply flex h-full flex-col overflow-hidden;

  .upper-section {
    @apply hidden min-h-[56px] flex-col justify-center bg-white p-4 shadow-md bp1:flex;
  }

  .course-container {
    @apply flex flex-row;

    .left-section {
      @apply relative flex h-full w-full flex-col overflow-hidden pt-5 bp1:px-3;

      .course-header {
        @apply relative flex flex-row gap-[16px];

        .back-button {
          @apply flex h-[40px] w-[40px] items-start justify-start p-1 bp1:hidden;
        }

        .course-title {
          @apply flex flex-col;

          h1 {
            @apply font-semibold bp1:text-[22px] bp1:text-xl mbp1:text-[24px];
          }
        }

        .course-description {
          @apply text-center text-sm;
        }
      }

      .course-class-container {
        @apply relative flex flex-row px-[40px] pt-5 bp1:px-0;

        .container {
          @apply flex w-full flex-col gap-3 bp1:pr-0;

          .video-container {
            @apply flex flex-col;

            .class-controllers {
              @apply flex w-full justify-between gap-2;
            }
          }
        }
      }
    }

    .right-section {
      @apply relative h-full min-w-[250px] max-w-[350px] overflow-hidden pt-10 sm:w-[280px] md:w-[300px] md:min-w-[300px] xl:w-[350px] xl:min-w-[390px] bp1:hidden;
    }
  }
}
