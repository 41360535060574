.comment-view {
  @apply flex flex-row justify-center items-start gap-3 w-full;

  [contenteditable]:focus {
    outline: 0px solid transparent;
  }

  .send-button-container {
    @apply flex justify-end items-center;
    .send-button {
      @apply px-4 h-[29px] bg-blue-secondary rounded-[8px] text-white flex justify-center items-center cursor-pointer;
      font-size: 10px;
    }
  }

  .toolbar-button {
    @apply text-center w-[25px] h-[25px] text-gray-600 hover:bg-gray-300 cursor-pointer rounded-[5px] flex justify-center items-center;

    &.active {
      @apply bg-gray-300;
    }
  }
}
