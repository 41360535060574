.container-list {
  font-family:
    Inter,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @apply w-full gap-2;

  .header-courses {
    @apply flex flex-row items-center justify-between gap-3 overflow-hidden;

    h1 {
      color: #1b2026;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      @apply line-clamp-2;
    }

    label {
      color: #000;
      text-align: right;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      opacity: 0.6;
    }
  }

  .courses-list {
    @apply flex flex-col gap-1;
    @apply h-full max-h-[450px];
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 10px; /* Ancho del scrollbar */
    }

    &::-webkit-scrollbar-track {
      background-color: #f1f1f1; /* Color de fondo del scrollbar */
    }

    &::-webkit-scrollbar-thumb {
      background-color: #888; /* Color del thumb (barra) */
      border-radius: 5px; /* Borde redondeado */
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #555; /* Cambia de color al pasar el mouse */
    }
  }
}
