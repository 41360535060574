.canvas {
  /* Gradient Color */
  --gradient-color-1: #1a1d3a;
  --gradient-color-2: #21284a;
  --gradient-color-3: #1e1e62;
  --gradient-color-4: #151923;
  height: 100vh;
  width: 100%;
  position: fixed;
  /*background-color: #1A1D3A;*/
  background: linear-gradient(
    40deg,
    #1e1e62 20%,
    #1a1d3a 48%,
    #21284a 70%,
    #151923 100%
  );
  z-index: -10;
}
