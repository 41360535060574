.cs-table {
  @apply flex w-full flex-col ;

  thead {
    tr {
      @apply flex w-full text-[14px] text-[#1B2026] border-b border-[#E2E2E2];

      th {
        .child {
          @apply flex min-h-[60px] opacity-60 font-normal ;
        }

        div {
          @apply my-auto items-start;
        }
      }
    }
  }

  tbody {
    tr {
      @apply flex w-full flex-row text-[13px] font-semibold text-[#1B2026];
      @apply border-b border-[#E2E2E2];

      td {
        @apply flex min-h-[50px];
      }

      div {
        @apply text-[13px] font-normal;
      }
    }
    tr:last-child {
      @apply border-b-0;
    }
  }

  .w-t6 {
    width: 60%;
  }
  .w-t5 {
    width: 50%;
  }
  .w-t4 {
    width: 40%;
  }
  .w-t3 {
    width: 30%;
  }
  .w-t2 {
    width: 20%;
  }
  .w-t15 {
    width: 15%;
  }
  .w-t1 {
    width: 10%;
  }
  .w-t05 {
    width: 5%;
  }
}
