.course-navigation-section {
  @apply flex cursor-pointer flex-row items-center gap-2 text-[#5368E5];

  p {
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.course-nav-layout {
  @apply mt-16 flex flex-col gap-2 p-2;

  h1 {
    color: #1b2026;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    @apply line-clamp-2;
  }

  span {
    color: #5368e5;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
